<script setup lang="ts">
import type { FormContext } from 'vee-validate';

export interface Props {
    disabled?: boolean;
    form?: FormContext<any>;
    height?: number;
    label?: string;
    modelValue?: FieldTextareaValue;
    name: string;
    required?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    form: undefined,
    height: 3,
    label: '',
    modelValue: undefined,
});

const emit = defineEmits<{
    'update:modelValue': [updatedValue: FieldTextareaValue];
}>();

const {
    handleBlur,
    handleChange,
    meta: fieldMeta,
    validate,
    value: fieldValue,
} = useField<FieldTextareaValue>(props.name, undefined, {
    form: props.form,
    standalone: props.modelValue !== undefined,
});

/** Uses modelValue prop if it exists and uses vee-validate if not. */
const modelValue = computed({
    get: () => {
        if (props.modelValue !== undefined) {
            return props.modelValue;
        } else {
            return fieldValue.value;
        }
    },
    set: (updatedValue: FieldTextareaValue) => {
        if (props.modelValue === undefined) {
            handleChange(updatedValue);
        }
        emit('update:modelValue', updatedValue);
    },
});

const isInvalid = computed(() => fieldMeta.touched && !fieldMeta.valid);

/** Updates fieldMeta using handleBlur, then validates field. */
const onBlur = () => {
    handleBlur();
    validate();
};

const id = useId();
</script>

<template>
    <FormsFieldWrap
        :id="id"
        :label="props.label"
        :meta="fieldMeta"
        :name="props.name"
        :required="props.required"
    >
        <textarea
            :id="id"
            v-model="modelValue"
            :disabled="props.disabled"
            :rows="props.height"
            :class="{
                input: true,
                invalid: isInvalid,
            }"
            @blur="onBlur"
        ></textarea>
    </FormsFieldWrap>
</template>

<style lang="postcss" scoped>
.input {
    padding: 0.5rem 1rem;
    width: 100%;
    border: 1px solid var(--color-dark-gray);
    border-radius: var(--size-input-radius);
    outline-color: var(--color-blue);
    color: var(--color-font-dark);
    font-size: 1rem;
    font-family: var(--font-family);

    &.invalid {
        border-color: var(--color-error);
        outline-color: var(--color-error);
    }
}
</style>
